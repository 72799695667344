import axios from "axios";
import { getUser } from "./oidc/plugin";
import AirtableService from "./services/AirtableService";


const http = axios.create ({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {'Content-Type': 'application/json'},
});

http.interceptors.request.use (
  function (config) {
    try {
      const token = getTokens();
      if (token) config.headers.Authorization = `Bearer ${token}`;
    }catch(e){}
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

http.interceptors.response.use (
  async function (response) {
    const url = response.config.url!;


    // -------------------------
    // Tracking Airtable
    // -------------------------
    if(url.includes('airtable') && !url.includes('tracking')){

      let type;
      let data;
      let sendTracking = false;

      // -------------------------
      // Comments
      // -------------------------
      const commentMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)\/comments/)
      if(commentMatch && response.config.method == 'post' && !sendTracking){
        const id = url.split('/')[2];
        type = 'comment';
        data = { action: 'create', idResource: id, idComment: response.data.id }
        sendTracking = true;
      }
      const updateCommentMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)\/comments\/([a-zA-Z0-9]+)/)
      if(updateCommentMatch && response.config.method == 'put' && !sendTracking){
        type = 'comment';
        data = { action: 'update', idResource: updateCommentMatch[1], idComment: response.data.id }
        sendTracking = true;
      }


      // -------------------------
      // Feedbacks
      // -------------------------
      const feedbackMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)\/feedback/)
      if(feedbackMatch && response.config.method == 'post' && !sendTracking){
        type = 'feedback';
        data = { action: 'create', idResource: feedbackMatch[1], idFeedback: response.data.id }
        sendTracking = true;
      }
      if(feedbackMatch && response.config.method == 'delete' && !sendTracking){
        type = 'feedback';
        data = { action: 'delete', idResource: feedbackMatch[1], idFeedback: response.data.id }
        sendTracking = true;
      }

      // -------------------------
      // Uploads
      // -------------------------
      const uploadMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)\/uploads/)
      if(uploadMatch && response.config.method == 'post' && !sendTracking){
        type = 'upload';
        data = { action: 'create', idResource: uploadMatch[1], idUpload: response.data.id }
        sendTracking = true;
      }
      const downloadMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)\/uploads\/([a-zA-Z0-9]+)\/download/)
      if(downloadMatch && response.config.method == 'get' && !sendTracking){
        type = 'upload';
        data = { action: 'download', idResource: downloadMatch[1], idUpload: downloadMatch[2] }
        sendTracking = true;
      }
      const deleteUploadMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)\/uploads\/([a-zA-Z0-9]+)/)
      if(deleteUploadMatch && response.config.method == 'delete' && !sendTracking){
        type = 'upload';
        data = { action: 'delete', idResource: deleteUploadMatch[1], idUpload: deleteUploadMatch[2] }
        sendTracking = true;
      }

      // -------------------------
      // Resources
      // -------------------------
      const cloneMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)\/clone/)
      if( cloneMatch && response.config.method == 'post' && !sendTracking){
        type = 'resource';
        data = { action: 'clone', idResource: cloneMatch[1], idCloned: response.data.id }
        sendTracking = true;
      }
      const resourceMatch = url.match(/\/airtable\/([a-zA-Z0-9]+)/)
      if(resourceMatch && response.config.method == 'post' && response.status == 201 && !sendTracking){
        type = 'resource';
        data = { action: 'create', idResource: response.data.id }
        sendTracking = true;
      }
      if(resourceMatch && response.config.method == 'put' && !sendTracking){
        type = 'resource';
        const action = response.data.status == 'DRAFT' ? 'update' : 'publish';
        data = { action: action, idResource: response.data.id, lastModified: response.data.lastModified }
        sendTracking = true;
      }
      if(resourceMatch && response.config.method == 'delete' && !sendTracking){
        type = 'resource';
        data = { action: 'delete', idResource: response.data.id }
        sendTracking = true;
      }


      // -------------------------
      // Tracking
      // -------------------------
      if(sendTracking){
        try {
          const req = await AirtableService.postTracking(
            {
              type: type,
              data: data
            }
          )
        }
        catch (e) {
          console.error(e)
        }
      }




    }

    return response;
  },
  function (error) {
    if(error.response.status == 401){
      localStorage.removeItem('user');
      window.location.assign(window.location.origin + '/login')
    }
    return Promise.reject (error);
  }
);

export default http;



function getTokens() {
  const tokens = getUser().access_token
  return tokens
}